import React from "react"

const Card = ({ children }) => (
  <div className="column is-4" >
    <div className="card is-usp card-equal-height p-t-md" css={{textAlign: 'center'}}>
      {children}
    </div>
  </div>
)

export default Card
