import React from "react"
import { Link } from 'gatsby'
import HeroImg from "../assets/ferry.png"

const HeroContent = () => (
  <section className="hero">
    <div className="columns is-vcentered is-centered">
      <div className="column">
        <div className="hero-body">
          <h1 className="title is-size-3-desktop is-size-3-tablet is-size-5-mobile has-text-grey-light is-spaced">
            帮助失眠的您走出失眠
          </h1>
          <h2 className="subtitle is-5 is-size-7-mobile has-text-grey-light is-spaced">
            失眠源自我们的生活，我们要回到生活中解决它。
          </h2>
          <div className="field is-grouped">
            <div className="control">
              <Link to="/help" className="button is-primary">求助指南</Link>
            </div>
            <div className="control">
              <Link to="/evaluate/login" className="button is-outlined">开始评估</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-hidden-mobile has-text-centered">
        <img css={{width:400, height:400}} src={HeroImg} alt="use-a-boat" />
      </div>
    </div>
  </section>
)

const Hero = () => <HeroContent />

export default Hero
