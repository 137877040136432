import React from "react"
import Cards from "../components/cards"
import Card from "../components/card"
import CardIcon from "../components/card-icon"
import CardHeadline from "../components/card-headline"
import { FaRoad, FaShareAlt, FaUserFriends } from "react-icons/fa"
import { Link } from 'gatsby'
import { OutboundLink } from "gatsby-plugin-google-analytics"

const cardLinkStyle = { display: 'block' }

const Introduction = () => (
  <section className="section is-medium">
    <Cards>
      <Card>
        <Link to="/tutorial/" css={cardLinkStyle}>
          <CardIcon Icon={FaRoad} />
          <CardHeadline>
            睡吧知识库
            </CardHeadline>
          <p className="card-content is-5">
            失眠的本质、成因、解决方案以及针对不同类型失眠的专题都在睡吧指南中有所讲解。
            通过阅读指南中的文章，你会清楚的知道自己该如何做才能走出失眠。
            </p>
        </Link>
      </Card>
      <Card>
        <Link to="/share/" css={cardLinkStyle}>
          <CardIcon Icon={FaShareAlt} />
          <CardHeadline>
            经验分享
            </CardHeadline>
          <p className="card-content is-5">
            经验分享中收集了吧友走出失眠后的心得。
            我们可以从这些文章中看到和自己类似的情况，这些分享会带给你足够的勇气和信心。
            </p>
        </Link>
      </Card>
      <Card>
        <OutboundLink href="http://www.douban.com/group/hellosleep/" css={cardLinkStyle}>
          <CardIcon Icon={FaUserFriends} />
          <CardHeadline>睡吧小组</CardHeadline>
          <p className="card-content is-5">
            豆瓣睡吧小组是睡吧网友求助、讨论的社区。
            每个失眠的人都可以在小组发表睡眠评估来寻求帮助，走出失眠的吧友可以在社区帮助其他失眠的吧友。
            </p>
        </OutboundLink>
      </Card>
    </Cards>
  </section>
)

export default Introduction
