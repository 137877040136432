import React from "react"
import { Link } from 'gatsby'
import { FaUserAlt, FaRegCalendarAlt } from "react-icons/fa"
import { getUsername, getArticleLink } from '../utils/methods'

const Meta = ({ text }) => (
  <span css={{ marginLeft: '0.2rem', marginRight: '0.6rem' }}>{text}</span>
)

const LatestContent = ({ posts }) => (
  <section className="section">
    <h1 className="title is-spaced">最新文章</h1>
    <div className="columns is-multiline">
      {posts.edges.map(({ node }, index) => (
        <div className="column is-one-third" key={`article-${index}`}>
          <article className="box">
            <Link className="is-size-5" css={{ marginBottom: '1rem' }} to={getArticleLink(node)}>
              {node.title}
            </Link>
            <p css={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
              <FaUserAlt />
              <Meta text={getUsername(node)} />
              <FaRegCalendarAlt />
              <Meta text={node.updatedAt} />
            </p>
            <div className="content">
              <p>{node.excerpt}</p>
            </div>
          </article>
        </div>
      ))}
    </div>
  </section>
)

export default LatestContent
