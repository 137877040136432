const getUsername = (post) => {
  if (post.type === 'share') return post.contributor.name

  return post.author.username
};

const getArticleLink = (post) => {
  return `/${post.type}/${post.id}`
};

const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validatePassword = (password) => {
  return password && password.length > 0;
}

const getHost = () => {
  const url = window.location.href
  const arr = url.split("/");

  return arr[0] + "//" + arr[2]
}

export const formatDate = date => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export {
  getUsername,
  getArticleLink,
  validateEmail,
  validatePassword,
  getHost
}
