import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import Hero from "../components/hero"
import Introduction from '../components/introduction'
import LatestContent from "../components/latest-content"

class IndexRoute extends React.Component {
  render() {
    const posts = this.props.data.allStrapiArticle

    return (
      <Layout location={this.props.location}>
        <div css={{ position: `relative` }}>
          <div css={{ flex: 1 }}>
            <Hero />
            <Introduction />
            <LatestContent posts={posts}/>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexRoute

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiArticle(sort: {order: DESC, fields: [updatedAt]}, limit: 10) {
      edges {
        node {
          id: strapiId
          excerpt
          title
          updatedAt(formatString: "YYYY-MM-DD")
          date
          type
          author {
            id
            username
          }
          contributor {
            name
          }
        }
      }
    }
  }
`
